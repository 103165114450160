import React, { useState } from 'react'
import logo from '../img/smartcard_logo.png';
import { Link } from 'react-router-dom';
import { IoMdLogIn } from "react-icons/io";
import { RiUserAddFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

function TopMenu() {
    return (
        <div className='blk-menu row '>
            <div className='col-sm-8'>
                <Link to='/'>
                    <img src={logo} alt='' />
                </Link>
                <p className='log-text'>
                    <Link to='https://www.otcmarkets.com/stock/SMKG/profile' target='_blank'>
                        <span>An OTC Markets Co. OTC:SMKG</span>
                    </Link>
                </p>
            </div>
            <div className='right-menu col-sm-4 '>
                <ul className='menu log-area'>
                    <li>
                    <Link to='https://www.facebook.com/otcsmkg/?ref=br_rs' target='_blank'>
                        <FaFacebook />
                    </Link>
                    </li>
                    <li>
                    <Link to='https://twitter.com/otcsmkg' target='_blank'>
                        <FaTwitter />
                    </Link>
                    </li>
                    <li>
                    <Link to='https://www.instagram.com/smartcard_marketing_sys/' target='_blank'>
                        <FaInstagram />
                    </Link>
                    </li> 
                    {/* <li>
                        <Link to='https://www.otcmarkets.com/stock/SMKG/profile' target='_blank'>
                            <div className='otc-sec'>
                                <img src={logo} alt='' />
                                <p><span>An OTC Markets Co. OTC:SMKG</span></p>
                            </div>
                        </Link>
                    </li> */}
                    {/* <li className='border-right1 '>
                        <Link className='yellow-text' to='/login'>
                            <RiUserAddFill />
                        </Link>
                    </li>
                    <li>
                        <Link className='yellow-text' to='/login'>
                            <IoMdLogIn />
                        </Link>
                    </li> */}
                </ul>
            </div>

        </div>
    )
}

export default TopMenu