import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Products from './components/Products'
import ExcutiveTeam from './components/ExcutiveTeam'
import Contact from './components/Contact'
import News from './components/News'
import Interview from './components/Interview'
import Main from './components/admin/Main'
import CompanyHistory from './components/CompanyHistory'
import TokencoinListing from './components/TokencoinListing'
import Services from './components/Services'
import Login from './components/Login'
import BusinessUseCase from './components/BusinessUseCase'

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/business-use-case' element={<BusinessUseCase />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/products' element={<Products />}></Route>
        <Route path='/excutiveteam' element={<ExcutiveTeam />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/news' element={<News />}></Route>
        <Route path='/interview' element={<Interview />}></Route>
        <Route path='/company-history' element={<CompanyHistory />}></Route>
        <Route path='/token-coin-listing' element={<TokencoinListing />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/admin' element={<Main />}></Route>
      </Routes>

    </div> 
  )
}

export default App
