import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import about from '../img/org/about.jpeg'
import { Button } from 'reactstrap'

function About() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>About us</h2>
                    <p className='text-light'>Home / About us</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-6 pb-3'>
                            <h2 className='h2-f'>Welcome to SmartCard  Marketing Systems Inc (OTC:SMKG).</h2>
                            <div>
                                <p className='text1 pt-3'>
                                    SmartCard Marketing Systems Inc (OTC:SMKG).. at its core offers a strategic architecture of Embedded PayTech and Cross-border modelling, seamlessly integrated with its PAAS solutions portfolio.  A solution that is marketplace centric in design & logic.
                                    <br />
                                    This critical thinking allows our clients to further their journey into Payments Automation, Orchestration and accessing Payment Schemes that have normally been a challenge either in reach or for their complexity stemming from process flows.

                                </p>
                                <div>
                                    <Button
                                        href="https://drive.google.com/file/d/1x8UWif3P0WusRg_R53IuoUyN_98wUSN9/view" color='warning'>Know More</Button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 pb-3'>
                            <img src={about} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <h4 className='pb-3'>Unlocking Mutual Growth and Innovation Through SmartCard's Strategic Collaborations </h4>
                        <div className='col-sm-6'>
                        <ul>
                            <li>Availability of Larger Tech "Platforms as a Service” with the Proprietary Portfolio and Resources of (22+ Platforms and Applications)
                            </li>
                            <li>Ready for Market White-Label and Private-Label Solutions</li>
                            <li>Customize, Brand and Scale with Security</li>
                            <li>Dedicated IT and Products Support Team</li>
                            <li>Interoperable Third Party Vendor collaborations with more than 60+ to activate for easy deployments including Risk and Fraud Monitoring, E-KYC, E-KYB, OCR, Face-Recognition, OTP and many others</li>
                            <li>Low CAPEX with flexible monthly plans</li>
                        </ul>
                        </div>
                        <div className='col-sm-6'>
                            <ul>
                                <li>Payment Partner Integrations with Cross-border Payment Rails for more than 55+ countries</li>
                                <li>Paytech PCI compliant "Terminal Management Solution” build and brand your own payment system</li>
                                <li>Broader base of Skills, Knowledge and experience of Payments and Fintech Industry</li>
                                <li>SLA protocols and support L1,L2,L3 options available</li>
                                <li>Ecosystem and Full Integration with IT Library resources available for deployment</li>
                                <li>Digital Transformation and accelerated deployment strategies with commerce models consultation planning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About