import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { Col, Row, Label, Input, Button } from 'reactstrap';

function Contact() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Contact us</h2>
                    <p className='text-light'>Home / Contact us</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className='contact-info'>
                                <h4>Contact info</h4>
                                <p >
                                    <FaMapMarkerAlt /> {' '}
                                    <span className='text-team'>
                                    20c Trolley Square, Wilmington De 19806 USA
                                    </span>
                                </p>
                                <p><FaPhoneAlt /> {' '} +1-844-THE-PAYMENT Intl </p>
                                <p><FaPhoneAlt /> {' '}  +1-718-717-8657 (USA) </p>
                                <p><FaPhoneAlt /> {' '} +1-514-504-2126 (Canada) </p>
                            </div>
                        </div>
                        <div className='col-sm-8'>
                            <div className='contact-form'>
                                <h4 className='text-center'>Get in touch</h4>
                                {/* row-cols-lg-auto g-3 align-items-center */}
                                <Row className="">
                                    <Col sm={6}>
                                        <Label>
                                            Name
                                        </Label>
                                        <Input
                                            type='text' placeholder='Full name'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Email
                                        </Label>
                                        <Input
                                            type='text' placeholder='Full name'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Phone
                                        </Label>
                                        <Input
                                            type='text' placeholder='Phone number'
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Label>
                                            Message
                                        </Label>
                                        <Input
                                            type='textarea' placeholder='Message'
                                        />
                                    </Col>
                                    <Col sm={12} className='pt-5'>
                                        <Button type='button' block color='primary'>Submit</Button>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d36553983.44087083!2d-96.00000000000001!3d56!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sus!4v1736175336215!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14590.451608405805!2d-75.57828527751599!3d39.75616685990644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd106db1f663%3A0xbe6dc2c0217d5180!2s20c%20Trolley%20Square%2C%20Wilmington%2C%20DE%2019806%2C%20USA!5e0!3m2!1sen!2sin!4v1736521443733!5m2!1sen!2sin"  width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact