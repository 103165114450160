import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import t1 from '../img/org/tok1.jpeg'
import t2 from '../img/org/tok2.jpeg'

import aa from '../img/coins/dtx.png';
import ascend from '../img/coins/asc1.png';
import bitconomy from '../img/coins/bicon1.png';
import bmart from '../img/coins/bitmart.png';
import coin from '../img/coins/coinph1.png';
import koinp from '../img/coins/koinpk.png';
import lbank from '../img/coins/lbank1.png';
import toobit from '../img/coins/toobit1.png';
import uzx from '../img/coins/uzx1.png';
import weex from '../img/coins/wexx.png';
import xy from '../img/coins/xt1.png';
import z from '../img/coins/z1.png';

import coinGe from '../img/coinGe.png';
import coinMark from '../img/coinMark.png';
import tv from '../img/tv.png';

import eth from '../img/eth.png';
import algorand from '../img/algorand.png';
import solan from '../img/solan.png';
import { Link } from 'react-router-dom'

function TokencoinListing() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Token/coin Listing</h2>
                    <p className='text-light'>Home / Token/coin Listing</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5 token'>
                    <div className='row'>
                        <div className='col-sm-6 '>
                            <h2 className='h2-f'>SmartCard Token/Coin Listing Services</h2>
                            <div>
                                <p className='text1 pt-3'>
                                    SmartCard provides an end-to-end solution for token/coin listing, marketing, and ecosystem support, ensuring seamless execution and visibility for your project. Our services include strategic consulting, technical implementation, compliance, marketing, and real-time monitoring through our platform.
                                    <br />
                                    SmartCard simplifies the complexity of token listing through an integrated, tech-driven approach. With SmartCard, your project stays on top of market movements, enabling data-driven decisions. From whitepaper creation to post-listing support, SmartCard ensures success at every step.
                                </p>
                                {/* <div>
                                    <Button
                                        href="https://drive.google.com/file/d/1x8UWif3P0WusRg_R53IuoUyN_98wUSN9/view" color='warning'>Know More</Button>
                                </div> */}
                                <div>
                                    Got a Token or Coin idea? Let's make it a reality! <br />
                                    📩Reach out to our expert at:<b> dvora@smartcardmarketingsystems.com</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <img src={t1} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <img src={t2} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <h4 className='text-center'>Listing Exchanges</h4>
                            {/* coin-list1 */}
                            <div className='row img-row pt-5'>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={toobit} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={ascend} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={bitconomy} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={bmart} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={coin} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={koinp} alt='' />
                                    </div>
                                </div>
                                {/* </div>
                            <div className='row img-row pt-5'> */}
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={lbank} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={aa} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={uzx} alt='' />
                                    </div>
                                </div>

                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={weex} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={xy} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-2 col-6'>
                                    <div>
                                        <img src={z} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <h4 className='text-center pb-3'>Reporting Websites</h4>
                            <div className='row imgg-row'>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={coinGe} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={coinMark} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={tv} alt='' className='width-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <h4 className='text-center pb-3'>Blockchain</h4>
                            <div className='row imgg-row'>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={eth} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={algorand} alt='' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={solan} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TokencoinListing