import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import qrcode_business from '../img/business_case_qrcode.png'
import { Button } from 'reactstrap'

function BusinessUseCase() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>SMKG BUSINESS USE CASE </h2>
                    <div>
                        <img src={qrcode_business} alt="" className='img-qr-code' />
                    </div>
                </div>
            </div>
            <div className='bg-dark'>
                <div className='container pt-5 pb-5 b-use-case'>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div>
                                <h3>IOT & AUTOMATION</h3>
                            </div>
                            <div>
                                <ul>
                                    <li>APN PRIVATE NETWORK</li>
                                    <li>GLOBAL DATA TRACKING </li>
                                    <li>ESIMS & ISIMS</li>
                                    <li>REALTIME DATA</li>
                                    <li>PAYMENTS AUTOMATION</li>
                                    <li>DEVICE TRACEABILITY</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3> WHITE-LABEL PAYMENT SWITCH</h3>
                            </div>
                            <div>
                                <p>PF SPONSORSHIP FOR CARD ACQUIRING </p>
                                <ul>
                                    <li>ECOMMERCE</li>
                                    <li>MOTO & EPOS PAYMENTS</li>
                                    <li>SETTLEMENT & CLEARING</li>
                                    <li>MPOS DEVICE CERTIFICATIONS</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>CHATBOT AUTOMATION</h3>
                            </div>
                            <div>
                                <p>AI CHAT BOT </p>
                                <ul>
                                    <li>SALES</li>
                                    <li>SUPPORT</li>
                                    <li>PAY MENTS</li>
                                    <li>BUSINESS PROCESSES </li>
                                    <li>TRUSTED DATA</li>
                                    <li>AUTOMATION</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div>
                                <h3> MARKETPLACE & MULTI-WALLET </h3>
                            </div>
                            <div>
                                <p>MULTI-COUNTRY CLIENT ORIGINATION </p>
                                <ul>
                                    <li>MARKETPLACE & WALLET</li>
                                    <li>ECOMMERCE & MPOS</li>
                                    <li>TOP-UP & SEND</li>
                                    <li>VIRTUAL CARD ISSUING CASH CARD </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>COMPLIANCE DESK</h3>
                            </div>
                            <div>
                                <p>TOKEN / COIN LISTING</p>
                                <ul>
                                    <li>WHITEPAPER</li>
                                    <li>LEGAL OPINION</li>
                                    <li>SMARTCONTRACT AUDIT</li>
                                    <li>PRIVATE PLACEMENT AGREEMENTS</li>
                                    <li>TOKEN / COIN SWAP</li>
                                    <li>OFF RAMPING</li>
                                    <li>TRADING VIEW / CMC / COINGECKO LISTING</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>CARIBBEAN ISLAND</h3>
                            </div>
                            <div>
                                <p>COUNTRY'S DIGITAL TRANSFORMATION</p>
                                <ul>
                                    <li>ONBOARDING</li>
                                    <li>KYC / KYB</li>
                                    <li>MARKETPLACE</li>
                                    <li>CRYPTO OTC DESK PAYMENTS</li>
                                    <li>TWALLET</li>
                                    <li>PAYMENTS & FX</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div>
                                <h3>MARKETPLACE & MULTI-WALLET</h3>
                            </div>
                            <div>
                                <p>MULTI-COUNTRY CLIENT ORIGINATION</p>
                                <ul>
                                    <li>MARKETPLACE & WALLET</li>
                                    <li>ECOMMERCE & MPOS</li>
                                    <li>TOP-UP & SEND</li>
                                    <li>VIRTUAL CARD ISSUING CASH CARD </li>
                                    <li>TRUSTED DATA</li>
                                    <li>AUTOMATION</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>PAYOUT CLAIMS REDEMPTION</h3>
                            </div>
                            <div>
                                <p>US NON-PROFIT DISASTER CLAIMS</p>
                                <ul>
                                    <li>MULTI PAYMENT CHANNEL ORIGINATION</li>
                                    <li>VOUCHER ISSUANCE</li>
                                    <li>INSTANT PAYMENTS</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>CARD ISSUER WALLETS</h3>
                            </div>
                            <div>
                                <p>ITL FX FOR PREPAID CARD ISSUER</p>
                                <ul>
                                    <li>CARD BIN FX FORWARD PAYMENTS</li>
                                    <li>FX PAYMENTS SPONSORSHIP NETWORKS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div>
                                <h3>BANKS & INSURANCE</h3>
                            </div>
                            <div>
                                <p>NEW IFE BANK VIRGIN ISLAND</p>
                                <ul>
                                    <li>DIGITAL BANKING PLATFORM</li>
                                    <li>INTEGRATED CORRESPONDING BANKING CHANNELS</li>
                                    <li>EMBEDDED 3RD PARTIES & DIGITAL PAYMENTS FOR THEIR MERCHANTS</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>BANKS & INSURANCE</h3>
                            </div>
                            <div>
                                <p>INTERNATIONAL BANK IN CANADA</p>
                                <ul>
                                    <li>CHECK21SAAS</li>
                                    <li>REMOTE SCANNING DEVICES FOR BRANCHES & TREASURY CLIENTS
                                    </li>
                                    <li>OCR DATA</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                <h3>RWA TOKENIZATION</h3>
                            </div>
                            <div>
                                <p>REAL WORLD ASSETS</p>
                                <ul>
                                    <li>DIGITAL ASSET TOKEN ISSUANCE & TOKENOMICS MODELLING</li>
                                    <li>CRYPTO OTC DESK PAYMENTS</li>
                                    <li>TREASURY ESCROW</li>
                                    <li>TRADING PAIR </li>
                                    <li>WALLETS</li>
                                    <li>CUSTODY</li>
                                    <li>EXCHANGE LISTING</li>
                                    <li>PAYMENTS ACCEPTANCE CHANNELS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BusinessUseCase