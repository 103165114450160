import React from 'react'
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaMapMarker } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import logo from '../img/big-logo.png';

import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import img1 from '../img/news/news.jpg'

function Footer() {
    return (
        <div className='footer bg-black1'>
            <div className='footer1 container pb-5'>
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className='center-phone'>
                            <img src={logo} alt='' className='width-100' />
                            {/* <img src={img1} alt='' className='width-100' /> */}
                        </div>
                        {/* <div>
                            <p className='text-light center-phone'>
                                SmartCard Marketing Systems Inc (SMKG:OTC) is a publicly listed company on the OTC Markets.
                                The company was established in 2006 and is an Entrepreneurial Design Studio of Payment ..
                            </p>
                        </div> */}
                    </div>
                    <div className='col-sm-2 padin-footer-menu'>
                        <h3 className='text-center0'>Quick links</h3>
                        <div className='center-phone'>
                            <ul>
                                <li>
                                    <Link to='/about'>Abouts</Link>
                                </li>
                                <li>
                                    <Link to='/products'>Products</Link>
                                </li>
                                <li>
                                    <Link to='/excutiveteam'>Executive team</Link>
                                </li>
                                <li>
                                    <Link to='/news'>News & Investors</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-2 padin-footer-menu'>
                        <h3 className='text-center0'>Services</h3>
                        <div className='center-phone'>
                            <ul>
                                <li>
                                    <Link to='/company-history'>Company History</Link>
                                </li>
                                <li>
                                    <Link to='/about'>About Us</Link>
                                </li>
                                <li>
                                    <Link to='/contact'>Contact Us</Link>
                                </li>
                                <li>
                                    <Link to='/services'>Services</Link>
                                </li>
                                {/* <li>
                                    <Link to='/'>Code of Conduct</Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className='col-sm-5'>
                        {/* <h3 className='text-center'>Contact Us</h3> */}
                        <div >
                            <form action="#" class="footer_newsletter_form">
                                <input type="email" class="footer_newsletter_input" placeholder="Your E-mail" required="required" />
                                <button class="footer_newsletter_button" type="submit">subscribe</button>
                            </form>
                        </div>
                        <p className='pt-3'>
                            <Link> <MdEmail /> {' '}
                                Sales@smartcardmarketingsystems.com</Link>
                        </p>
                        <p>
                            <Link> <IoCall /> {' '}
                                +1-514-504-2126</Link>
                        </p>
                        <p>
                            <Link> <FaMapMarker /> {' '}
                                20c Trolley Square, Wilmington De 19806 USA
                            </Link>
                        </p>
                        <div>
                            <ul className='social-menu'>
                                <li>
                                    <Link to=''>
                                        <FaFacebookSquare />
                                    </Link>
                                </li>
                                <li>
                                    <Link to=''>
                                        <FaTwitterSquare />
                                    </Link>
                                </li>
                                <li>
                                    <Link to=''>
                                        <FaLinkedin />
                                    </Link>
                                </li>
                                <li>
                                    <Link to=''>
                                        <IoLogoYoutube />
                                    </Link>
                                </li>
                                <li>
                                    <Link to=''>
                                        <FaInstagram />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer2'>
                <p className='text-center text-light black-bg py-3'>
                    Copyright © {new Date().getFullYear()} | SmartCard Marketing Systems Inc. (OTC:SMKG) | All Rights Reserved
                </p>
            </div>
        </div>
    )
}

export default Footer