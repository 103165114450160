import React from 'react'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

function Main() {
    return (
        <div>
            <div>
                <Sidebar>
                    <div>
                        <h3>Admin</h3>
                    </div>
                    <Menu>
                        <MenuItem component={<Link to="/documentation" />}> Documentation</MenuItem>
                        <MenuItem component={<Link to="/calendar" />}> Calendar</MenuItem>
                        <MenuItem component={<Link to="/e-commerce" />}> E-commerce</MenuItem>
                    </Menu>
                </Sidebar>
            </div>
        </div>
    )
}

export default Main