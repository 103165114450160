import React, { useState } from 'react'
import logo from '../img/smartcard_logo.png';
import intro from '../img/org/about.jpeg'
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button,
} from 'reactstrap';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import Slider from "react-slick";

// import { FaInstagram } from "react-icons/fa";
import m1 from '../img/m1.jpg';
import m2 from '../img/m2.jpg';
import m3 from '../img/m3.jpg'
import Footer from './Footer';
import NavbarMenu from './NavbarMenu'
import TopMenu from './TopMenu';

import vimg1 from '../img/vector/imgg1.png';
import vimg2 from '../img/vector/imgg2.png';
import vimg3 from '../img/vector/imgg3.png';
import vimg4 from '../img/vector/imgg4.png';
import vimg5 from '../img/vector/imgg5.png';
import vimg6 from '../img/vector/imgg6.png';
import slideimg from '../img/org/home.jpeg';

import guide from '../img/doc/guide.pdf';

function Home() {


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div>
            <div className='container_'>
                <TopMenu />
                <NavbarMenu />
                <div className="slider-container">
                    {/* <Slider {...settings}>
                        
                        <div className='slide'>
                            <h3>2</h3>
                        </div>
                        <div className='slide'>
                            <h3>3</h3>
                        </div>
                        <div className='slide'>
                            <h3>4</h3>
                        </div >
                        <div className='slide'>
                            <h3>5</h3>
                        </div>
                        <div className='slide'>
                            <h3>6</h3>
                        </div>
                    </Slider> */}
                    <div className='slide_0'>
                        <img src={slideimg} alt='' className='width-100' />
                    </div>
                </div>

                <div class="intro">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="intro_image text-lg-right ">
                                    <img src={intro} alt="" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="intro_content">
                                    <div class="intro_title_container">
                                        <h1 class="intro_title">Welcome to SmartCard Marketing Systems Inc (OTC:SMKG).</h1>
                                    </div>
                                    <div class="intro_text">
                                        <p class="abouttext">
                                            SmartCard Marketing Systems Inc (OTC:SMKG). at its core offers a strategic architecture of Embedded PayTech and Cross-border modelling, seamlessly integrated with its PAAS solutions portfolio.  A solution that is marketplace centric in design & logic.
                                            <br />
                                            This critical thinking allows our clients to further their journey into Payments Automation, Orchestration and accessing Payment Schemes that have normally been a challenge either in reach or for their complexity stemming from process flows.

                                            {/* SmartCard Marketing Systems Inc (OTC:SMKG) an Industry leader in Platforms as a Service Commerce Strategies specialized Industry cloud, e-commerce and mobility applications to the global BFSI Sector. The company an entrepreneurial boutique providing commercial strategies with a proprietary portfolio of applications and wireframes with embedded paytech for Banking, Retail,Marketplaces, Mobile Wallets, Digital ID, Blockchain, Digital Tokenization, Events Management and Ride Booking industries.For more info visit
                                            <a href="https://www.smartcardmarketingsystems.com/" target="_blank">www.smartcardmarketingsystems.com</a> or visit our business applications marketplace at
                                            <a href="https://emphasispay.com/" target="_blank">www.Emphasispay.com</a> */}
                                        </p>
                                    </div>
                                    <div class="link_button intro_button">
                                        <a href="/about">read more</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container pt-3 pb-3 serv mission'>
                    <div className='pb-5'>
                        <h4 className='text-center msh1 '>Our Mission & Strategy</h4>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <Link to={guide} target='_blank'>
                                <div className='main-div'>
                                    {/* <div className='text-center'>
                                        <img src={vimg6} alt="" className='img-vec' />
                                    </div> */}
                                    <h3 className='text-center'>PLATFORM AS A SERVICE</h3>
                                    <div className='pt-1'>
                                        <p className='text1'>
                                            Platforms as a Service also known as PAAS. The company's white-label offering through licensing
                                            provides the BFSI and Telecom Sectors a fast-track to Digital Transformation with Multi-Tenant
                                            Marketplace platforms, stacked utilities, embedded payments, blockchain and 3rd party integrated
                                            vendors to provide a seamless bespoke deployment strategy.

                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-sm-4'>
                            <Link to="https://emphasispay.com/" target='_blank'>
                                <div className='main-div'>
                                    {/* <div className='text-center'>
                                        <img src={vimg1} alt="" className='img-vec' />
                                    </div> */}
                                    <h3 className='text-center'> EMPHASISPAY</h3>
                                    <div className='pt-1'>
                                        <p className='text1'>
                                            The company’s CRM marketplace is developed for its Partners, Agents and Clients. It offers intuitive information & best practices for Industry solutions
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='col-sm-4 '>
                            <Link to="/products">
                                <div className='main-div'>
                                    {/* <div className='text-center'>
                                        <img src={vimg3} alt="" className='img-vec' />
                                    </div> */}
                                    {/* vimg3 */}
                                    <h3 className='text-center'>MANAGED SOLUTIONS</h3>
                                    <div className='pt-1'>
                                        <p className='text1'>
                                            We offer our clients and partners an extension to their business processes. Our Managed Solutions cater to Product Development, Cloud Management Technical Support and Production deployment.
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
                <div className='container pt-3 pb-3 pb-5 mission'>
                    <div className='row'>

                        <div className='col-sm-4'>
                            <div>
                                {/* <div className='center-phone'>
                                    <img src={m1} alt='' />
                                </div> */}
                                <h3 className='text-center'>AML POLICY & PROCEDURE</h3>
                                <div>
                                    <p className='text1'>
                                        Failure to have an effective anti-money laundering (AML) compliance program has seen
                                        financial intelligence units (FIUs) hand out record fines in the tens of millions of dollars against
                                        organizations - fines that show they are serious about their call for compliance.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                {/* <div className='center-phone'>
                                    <img src={m2} alt='' />
                                </div> */}
                                <h3 className='text-center'>CODE OF CONDUCT</h3>
                                <div>
                                    <p className='text1'>
                                        A conflict of interest occurs when the private interests of a Covered Party interfere, or appear to interfere, with the interests of the Company as a whole. A
                                        conflict of interest may arise from many different scenarios, including (but not limited to) outside employment, improper personal benefits, financial interests or
                                        other financial transactions
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div>
                                {/* <div className='center-phone'>
                                    <img src={m3} alt='' />
                                </div> */}
                                <h3 className='text-center'>ESG PRACTICES</h3>
                                <div>
                                    <p className='text1'>
                                        The global environmental effects has an increased focus on sustainability and participation. At
                                        SmartCard Marketing Systems Inc. (OTC:SMKG) we focus on the digital transformation for
                                        organizations which enables towards both sustainability and participation in bringing a positive change
                                        for us and for the partners and clients we work with.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Home