import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import about from '../img/f1.png'
// import { Button } from 'reactstrap'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { Col, Row, Label, Input, Button } from 'reactstrap';
function Login() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Login</h2>
                    <p className='text-light'>Home / Login</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-7 '>
                            <div className='contact-form'>
                                <h4 className='text-center'>Register new user</h4>
                                {/* row-cols-lg-auto g-3 align-items-center */}
                                <Row className="">
                                    <Col sm={6}>
                                        <Label>
                                            First name
                                        </Label>
                                        <Input
                                            type='text' placeholder='Full name'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Last Name
                                        </Label>
                                        <Input
                                            type='text' placeholder='Full name'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Email
                                        </Label>
                                        <Input
                                            type='text' placeholder='Full name'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Phone
                                        </Label>
                                        <Input
                                            type='text' placeholder='Phone number'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                            Password
                                        </Label>
                                        <Input
                                            type='password' placeholder='*********'
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label>
                                        Confirm Password
                                        </Label>
                                        <Input
                                            type='password' placeholder='*********'
                                        />
                                    </Col>
                                   
                                    <Col sm={12} className='pt-5'>
                                        <Button type='button' block color='primary'>Submit</Button>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                         <div className='col-sm-4 offset-sm-1'>
                            <div className='contact-form'>
                                <h4 className='text-center'>Login</h4>
                                <Row className="">
                                    
                                    <Col sm={12}>
                                        <Label>
                                            Emial
                                        </Label>
                                        <Input
                                            type='password' placeholder='Email'
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Label>
                                        Password
                                        </Label>
                                        <Input
                                            type='password' placeholder='*********'
                                        />
                                    </Col>
                                   
                                    <Col sm={12} className='pt-5'>
                                        <Button type='button' block color='primary'>Login</Button>
                                    </Col>

                                </Row>
                            </div>
                        </div> 
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login