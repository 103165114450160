import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import img1 from '../img/news/news1.jpg'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import ReactPlayer from 'react-player/lazy'


function Interview() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>CEO INTERVIEW</h2>
                    <p className='text-light'>Home / CEO INTERVIEW</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3>CEO INTERVIEW</h3>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=GtC3xxnuWEA&ab_channel=SmartcardMarketingSystemsInc.OTC%3ASMKG'
                                    className='width-100' width='100%' />
                                    </div>
                                    <div className='pt-3'>
                                        {/* <p><span className='primaryc'> TECHNOLOGY /</span> Apr 7, 2022</p> */}
                                        <h3>SmartCard Marketing; Diving Deeper Into The Business Itself</h3>
                                        
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                    <ReactPlayer 
                                    url='https://www.youtube.com/watch?v=vdk-Xi3jkVA&t=3s'
                                    className='width-100' width='100%' />
                                    </div>
                                    <div className='pt-3'>
                                        {/* <p><span className='primaryc'> TECHNOLOGY /</span> Apr 7, 2023</p> */}
                                        <h3>Smart Card Marketing; Diving into the Company with CEO Massimo Barone</h3>
                                        
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                    <ReactPlayer 
                                    url='https://www.youtube.com/watch?v=LdnmTmZAUfQ&ab_channel=SmartcardMarketingSystemsInc.OTC%3ASMKG'
                                    className='width-100' width='100%' />
                                    </div>
                                    <div className='pt-3'>
                                        {/* <p><span className='primaryc'> TECHNOLOGY /</span> Apr 7, 2023</p> */}
                                        <h3>SmartCard Marketing; Acquiring Chat Bot AI & OriginatorX Underway in Ethereum Blockchain Offerings</h3>
                                        
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                    <ReactPlayer 
                                    url='https://www.youtube.com/watch?v=q-8Wg13Ab1A'
                                    className='width-100' width='100%' />
                                    </div>
                                    <div className='pt-3'>
                                        {/* <p><span className='primaryc'> TECHNOLOGY /</span> Apr 7, 2023</p> */}
                                        <h3>SmartCard Marketing; Key Regulatory Requirements Completed for Services as an E2E Provider</h3>
                                        
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                    <ReactPlayer 
                                    url='https://www.youtube.com/watch?v=n0DVjuyXHQo&t=13s&ab_channel=SmartcardMarketingSystemsInc.OTC%3ASMKG'
                                    className='width-100' width='100%' />
                                    </div>
                                    <div className='pt-3'>
                                        {/* <p><span className='primaryc'> TECHNOLOGY /</span> Apr 7, 2023</p> */}
                                        <h3>SmartCard Marketing; 2021 Financial Results Released</h3>
                                        
                                    </div>
                                </div>
                                {/*  */}
                               
                                {/* <div className='col-sm-6'>
                                    <div>
                                    
                                    </div>
                                    <div className='pt-3'>
                                    <p><span className='primaryc'> TECHNOLOGY /</span> March 26, 2020</p>
                                        <h3>There may be no consoles in the future ea exec says</h3>
                                        <p>
                                            The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                    <p><span className='primaryc'> TECHNOLOGY /</span> March 26, 2020</p>
                                        <h3>There may be no consoles in the future ea exec says</h3>
                                        <p>
                                            The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                    <p><span className='primaryc'> TECHNOLOGY /</span> March 26, 2020</p>
                                        <h3>There may be no consoles in the future ea exec says</h3>
                                        <p>
                                            The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with sandy shower…
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Interview