import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import img1 from '../img/org/com-h1.jpeg'
import img2 from '../img/org/com-h2.jpeg'

function CompanyHistory() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Company History</h2>
                    <p className='text-light'>Home / Company History</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <img src={img1} alt='' className='width-100' />
                        </div>
                        <div className='col-sm-12'>
                            <img src={img2} alt='' className='width-100' />
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CompanyHistory