import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import twiter from '../img/team/icons8-twitter-circled.gif'
import linkden from '../img/team/icons8-linkedin-circled.gif'
import email from '../img/team/email.png';

import ceo from '../img/team/ceo.png'
import dhar from '../img/team/president.png'
import founder from '../img/team/founder.png';
import cto from '../img/team/cto.png';
import manager from '../img/team/manager.png'
import cpa from '../img/team/cpa.png';

import { Button } from 'reactstrap'


function ExcutiveTeam() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Excutive Team</h2>
                    <p className='text-light'>Home / Excutive Team</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row our-team'>
                        <div className='col-sm-12'>
                            <p className='text-team'>
                            Dynamic Business Executives with a proven track record in Payment technology and Startup growth. 
                            Experts in driving innovation, scaling operations, and delivering strategic solutions that enhance 
                            customer experiences and boost revenue. Adept at building high-performing teams and forging strategic
                             partnerships to accelerate business success in competitive markets.
                            </p>
                        </div>
                        <div className='col-sm-6 border-right-1'>
                            <div className='text-center'>
                                <img src={ceo} alt='' />
                                <h3>Massimo Barone</h3>
                                <Button color='primary'>CEO and Founder</Button>
                                <div class="sosl">
                                    {/* https://twitter.com/massimobarone */}
                                    <a href="https://twitter.com/massimobarone" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a>

                                    <a href="https://www.linkedin.com/in/massimo-barone-1401227/" target="_blank">
                                    {/* https://www.linkedin.com/in/massimo-barone-1401227/   mbarone@smartcardmarketingsystems.com */}
                                        <img src={linkden} />
                                    </a>
                                    <a href="mailto:mbarone@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a>
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                                {/* A Montreal born entrepreneur with over 25 years of entrepreneurial experience. Mr.
                                Barone joined the technology sector in the late 90's after leaving the family Restaurants and Hotel
                                Concessions business. Initially leaving the industry to be part of one of the first online bank offerings
                                by the Major 5 in Canada in the early 90's, Mbanx, he realized the possibilities and immediately began
                                focusing his interests in the alternative payments sector. This transition became the foundation for
                                his technology expertise and start-ups throughout the 90's and his success today at SmartCard Marketing
                                Sys Inc OTC:SMKG. .... */}
                                A Montreal born entrepreneur with over 25 years of entrepreneurial experience. Mr. Barone joined the technology sector in the late
                                 90's after leaving the family Restaurants and Hotel Concessions business. Initially leaving the industry to be part of one of the
                                  first online bank offerings by the Major 5 in Canada in the early 90's, Mbanx, he realized the possibilities and immediately began
                                   focusing his interests in the alternative payments sector. This transition became the foundation for his technology 
                                   expertise and start-ups throughout the 90's and his success today at SmartCard Marketing Sys Inc OTC:SMKG. <br /><br />

                                During the past 25 years he founded three tech companies, each of which were successful in securing multiple series of investment 
                                rounds of capital, both from the private and institutional sectors. Additionally, all 3 tech companies were transformed from 
                                private to Public Co's each within their first 2-3 years of inception. <br /><br />

                                Mr. Barone has developed a significant network of people, financial institutions and enterprises around the globe. He has also 
                                developed a significant reach into Asia & India. <br /><br />

                                Today Mr. Barone continues to develop and implement technology solutions for the Fintech & Retail marketplace. He is the Founder & 
                                CEO of SmartCard Marketing Systems Inc. (OTC:SMKG) a Fintech company with Banks, Telecoms & Retail Enterprises as customers. 
                                His also works with many start-ups to develop and commercialize service solutions. <br /><br />

                            </p>
                        </div>
                        <div className='col-sm-6'>
                            <div className='text-center'>
                                <img src={dhar} alt='' />
                                <h3>Dharmesh Vora</h3>
                                <Button color='primary'>President Enterprise Solutions</Button>
                                <div class="sosl">
                                    <a href="https://twitter.com/Darmess" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a>

                                    <a href="https://www.linkedin.com/in/darmesv-salesspecialist/" target="_blank">
                                        <img src={linkden} />
                                    </a>
                                    <a href="mailto:dvora@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a>
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                                {/* Dharmesh Vora is a sales specialist with 15 years of experience in Technology & Consulting Industry.
                                He is an International Trade & Business graduate with multiple diploma & PG in Electrical engineer & Management
                                studies.
                                <br />
                                He has worked a proven trac record and has worked with global organizations like Franklin Covey and Antal International
                                with extensive hands on training on global material offered by BlessingWhite, Mahaan Khalsa and Dr. Stephen Covey. */}
                                Dharmesh Vora is a sales specialist with 15 years of experience in Technology & Consulting Industry. He is an International Trade &
                                 Business graduate with multiple diploma & PG in Electrical engineer & Management studies. <br /><br />

                                He has worked a proven trac record and has worked with global organizations like Franklin Covey and Antal International with extensive
                                 hands on training on global material offered by BlessingWhite, Mahaan Khalsa and Dr. Stephen Covey. <br /><br />

                                He is currently in the Indian subcontinent working closely with senior leadership teams, partners & distributors to identify 
                                the opportunities & challenges faced in the organization & design a best fit solution. <br /><br />

                                At SMKG he has collaborated with major channels & institution like Mumbai Fintech Hub, Visa Net, Wipro, Compuage Ltd. enabling a wider 
                                reach through their network in SAARC, EU, Middle East & Africa region. <br /><br />

                            </p>
                        </div>

                    </div>

                    <div className='row our-team pt-5'>
                        <div className='col-sm-6 border-right-1'>
                            <div className='text-center'>
                                <img src={founder} alt='' />
                                <h3>Paolo Continelli</h3>
                                <Button color='primary'>CBDO</Button>
                                <div class="sosl">
                                    <a href="https://twitter.com/ContinelliPaul" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a>

                                    <a href="https://www.linkedin.com/in/paolo-continelli-6592a17a/" target="_blank">
                                        <img src={linkden} />
                                    </a>
                                    <a href="mailto:pcontinelli@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a>
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                                {/* Mr. Continelli has over 20 years of experience as an entrepreneur and a visionary in the payment and technology space.
                                He has extensive experience in general contracting having grown into a family business, which he then took operational
                                and sales control in the late 90's and successfully tripled the sales and fleet of the company and later sold. Since
                                then he got involved in the payment sector looking for a challenge and change. .... */}
                                Mr. Continelli has over 20 years of experience as an entrepreneur and a visionary in the payment and technology space. 
                                He has extensive experience in general contracting having grown into a family business, which he then took operational 
                                and sales control in the late 90's and successfully tripled the sales and fleet of the company and later sold. 
                                Since then he got involved in the payment sector looking for a challenge and change. He has been involved in multiple start-ups from 
                                initial seed capital, concept to live production while working in global markets. During the past 10 years he has worked extensively 
                                with banks, telecoms and variety of businesses in Asia to implement payment applications., Mr. Continelli is currently responsible for 
                                business development in multiple markets for SmartCard Marketing Systems Inc.

                            </p>
                        </div>
                        <div className='col-sm-6'>
                            <div className='text-center'>
                                <img src={cto} alt='' />
                                <h3>Lee Tang </h3>
                                <Button color='primary'>CTO</Button>
                                <div class="sosl">
                                    {/* <a href="https://www.linkedin.com/in/leetang/" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a> */}
                                    <a href="https://www.linkedin.com/in/leetang/" target="_blank">
                                        <img src={linkden} />
                                    </a>
                                    <a href="mailto:ltang@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a>
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                                {/* Mr. Tang graduates from Concordia University with a Bachelor degree in Computer Science is a seasoned senior IT consultant
                                with more than 20 years of experience in IT and Telecom sectors. He worked with clients from various industries like FinTech,
                                Pharmaceutical, Marketing, Retail, BPO, Nature Resources and International Trading. He held many important key IT roles
                                and positions in his IT life, from Systems Director, to Data Center Architect, to VP of IT and Security, to CTO.
                                His broad knowledge of different aspects of the IT makes him a great asset to any company. ... */}
                                Mr. Tang graduates from Concordia University with a Bachelor degree in Computer Science is a seasoned senior IT consultant with more
                                 than 20 years of experience in IT and Telecom sectors. He worked with clients from various industries like FinTech, Pharmaceutical,
                                  Marketing, Retail, BPO, Nature Resources and International Trading. He held many important key IT roles and positions in his IT life,
                                from Systems Director, to Data Center Architect, to VP of IT and Security, to CTO. His broad knowledge of different aspects of the IT 
                                makes him a great asset to any company.<br /><br />

                                Mr. Tang's extensive experience on new start-ups grants him to be an ideal partner to have on new ventures.
                                 He knows no single approach is the right one for every start-up company, the dynamic environment and limited 
                                resources of the start-ups make each one very unique and challenge. That's why Lee often finds creative 
                                solutions in order to satisfy the specific needs from business side, helping company to reduce TCO while maximizing ROI, paving the way
                                 towards success.
                            </p>
                        </div>

                    </div>

                    <div className='row our-team pt-5'>
                        <div className='col-sm-6 border-right-1'>
                            <div className='text-center'>
                                <img src={manager} alt='' />
                                <h3>Michele (Mike) Tasillo </h3>
                                <Button color='primary'>Finance Manager</Button>
                                <div class="sosl">
                                    <a href="https://twitter.com/Miketasillo" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a>

                                    <a href="https://www.linkedin.com/in/mike-tasillo-aa934b80/" target="_blank">
                                        <img src={linkden} />
                                    </a>
                                    <a href="mailto:mtasillo@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a>
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                            Mr. Michele Tasillo, also known as Mike, serves as a Finance Manager of Smart Card Marketing Systems Inc. Mr. Tasillo was the Founder of Hybrid PayTech World Inc. (known as Freeport Capital Inc.), served as President and Chief Financial Officer until January 2014. He has been active as a Consultant and Educator for the past 20 years, specializing in ISO Certification Standards in the Urban Planning in the Construction Industry dealing with Municipal, Provincial and Foreign contracts. He also brings vast experience with project management, implementation and deployment of products. He served as a Director of Hybrid PayTech World Inc. (Freeport Capital Inc.) from November 19, 2009 to February 8, 2014.

                            </p>
                        </div>
                        <div className='col-sm-6'>
                            <div className='text-center'>
                                <img src={cpa} alt='' />
                                <h3>Eric M. Sherb  </h3>
                                <Button color='primary'>CPA</Button>
                                <div class="sosl">
                                    {/* <a href="https://twitter.com/massimobarone" target="_blank">
                                        <img src={twiter} alt='' />
                                    </a> */}
                                    <a href="https://www.linkedin.com/in/eric-sherb-395aa826/" target="_blank">
                                        <img src={linkden} />
                                    </a>
                                    {/* <a href="mailto:mbarone@smartcardmarketingsystems.com">
                                        <img src={email} alt='' />
                                    </a> */}
                                </div>
                            </div>
                            <p className='pt-3 text-justify text-team'>
                            Eric is a CPA with over 11 years experience in accounting advisory, auditing and mergers and acquisitions. He began his career at PricewaterhouseCoopers and has several years’ experience in mid‐size audit and consulting firms with clients in a variety of industries. Eric is the founder of EMS Consulting Services, which provides outsourced CFO services to private and public companies. Eric has extensive experience in SEC financial reporting, audit and IPO readiness, financial modeling and technical advisory. Eric graduated with a BBA from Emory University in Accounting and Finance.
                            </p>
                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </div >
    )
}

export default ExcutiveTeam