import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import serv1 from '../img/org/s1.jpeg'
import serv2 from '../img/org/s2.jpeg'
import serv3 from '../img/org/s3.jpeg'
import serv4 from '../img/org/s4.jpeg'

import { Button } from 'reactstrap'

function Services() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>Services</h2>
                    <p className='text-light'>Home / Services</p>
                </div>
            </div>
            <div>
                <div className='container  pb-5'>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <img src={serv1} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <img src={serv2} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <img src={serv3} alt='' className='width-100' />
                        </div>
                    </div>
                    <div className='row pt-5'>
                        <div className='col-sm-12'>
                            <img src={serv4} alt='' className='width-100' />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Services