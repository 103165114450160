import React from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'
import img1 from '../img/news/news.jpg'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
function News() {
    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>News</h2>
                    <p className='text-light'>Home / News</p>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3>News</h3>
                            <div className='row news-row'>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            July 5th, 2023
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-a-Marketplace-Technology-Co-Creating-Accelerated-Platforms-For-The-Adoption-fo?id=345353' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTCQB:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                            SMARTCARD MARKETING SYSTEMS INC. (OTCQB:SMKG) LATAM MARKET TO IGNITE MARKET GROWTH WITH PARTNERS TO DRIVE DEEP TECHNOLOGY IN LATAM CORRIDOR.
                                        </p>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            July 3rd, 2023
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SMARTCARD-MARKETING-SYSTEMS-INC-OTCSMKG--STAKEHOLDERS-AND-INVESTORS-UPDATE-FOR-FILINGS?id=406367' target='_blank'>
                                            STAKEHOLDERS AND INVESTORS UPDATE FOR FILINGS
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB), a Marketplace Technology Co. Creating Accelerated Platforms For The Adoption for Blockchains, Crypto Currencies, NFTs & Payments As a Service Offering
                                        </p>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            June 29th, 2023
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SMARTCARD-MARKETING-SYSTEMS-INC-OTCSMKG--STAKEHOLDERS-AND-INVESTORS-UPDATE-FOR-FILINGS?id=406367' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                            SMARTCARD MARKETING SYSTEMS INC. (OTCQB:SMKG) - ANNOUNCES INNOVATIVE DIGITAL ASSETS TOKENIZATION FEATURES AND A DIGITAL ASSETS / CRYPTO INDUSTRY UNDER A MAKEOVER.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            June 27th, 2023
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-OTCQBSMKG-Announces-Its-Platforms-as-a-Service-Digital-Strategy-PAAS--PPAAS-White-Label-?id=405712' target='_blank'>
                                            OTC Disclosure & News Service
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc. (OTCQB:SMKG) Announces Its Platforms as a Service Digital Strategy #PAAS & #PPAAS White Label Licensing Offerings
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            June 16th, 2023
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SMARTCARD-MARKETING-SYSTEMS-INC-OTCQBSMKG-Banks-Are-Set-To-Spend-57bn-on-Legacy-Payments-Technology-in-2028--PAYTECH-IND?id=404634' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG) OTC Disclosure & News Service
                                            </a>
                                        </h3>
                                        <p>
                                        SMARTCARD MARKETING SYSTEMS INC. (OTCQB:SMKG) Banks Are Set To Spend $57bn on Legacy Payments Technology in 2028 – PAYTECH INDUSTRY TO PERFORM
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            June 6th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-is-Pleased-to-Announce-Entering-a-Joint-Venture-Partnership-with-Aces-Group-LL?id=359983' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG) OTC Disclosure & News Service
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB) Payments Industry News and Spotlight on Value Strategy of Payment Facilitator (PF) Licenses and Opportunities
                                        </p>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            May 31st, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-Payments-Industry-News-and-Spotlight-on-Value-Strategy-of-Payment-Facilitator-?id=359390' target='_blank'>
                                            OTC Disclosure & News Service
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (OTC:SMKG) Offering in Payments Sector With RegTech and Embedded Artificial Intelligence Creates a Unique Monitoring and Tracking Service for Compliance, Underwriting and Real-time Monitoring
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            May 11th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-OTCSMKG-Offering-in-Payments-Sector-With-RegTech-and-Embedded-Artificial-Intelligence-Cr?id=356579' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                            SMARTCARD MARKETING SYSTEMS INC. (OTCQB:SMKG) - ANNOUNCES INNOVATIVE DIGITAL ASSETS TOKENIZATION FEATURES AND A DIGITAL ASSETS / CRYPTO INDUSTRY UNDER A MAKEOVER.
                                        </p>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            May 6th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-OTCMKG-The-Power-of-Blockchain-Card-Payments-and-Cross-Border-Payment-Rails-for-Fintech-?id=355965' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                            SmartCard Marketing Systems Inc (OTC:SMKG) Offering in Payments Sector With RegTech and Embedded Artificial Intelligence Creates a Unique Monitoring and Tracking Service for Compliance, Underwriting and Real-time Monitoring
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            Apr 20th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-Payments-Lab-Completion-and-Sales-Desk-for-Several-Countries-Key-to-Growth-and?id=353095' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc. (SMKG:OTCQB) Payments Lab Completion and Sales Desk for Several Countries Key to Growth and Defining Industry Advantage Through 2022 to 2026
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            Apr 6th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-Market-Research--Studies-with-Sources-on-Payments-Crypto-NFT-Blockchain-Metave?id=351278' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB) Market Research & Studies with Sources on Payments, Crypto, NFT, Blockchain, Metaverses & DAO.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                        Apr 5th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-Releases-The-Ambassadors-Partner-Program-Pricing-Matrix-for-Partner-FIs-and-En?id=351071' target='_blank'>
                                                SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG) OTC Disclosure & News Service

                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB) Releases The Ambassadors Partner Program Pricing Matrix for Partner FIs and Enterprises in FinTech And PayTech for 2022
                                        </p>
                                    </div>
                                </div>


                                {/*  */}
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                        Mar 18th, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-Production-Deployments-In-South-East-Asia-LATAM--India-Are-Now-Available-ToCha?id=348419' target='_blank'>
                                                SmartCard Marketing Systems Inc (OTC:SMKG).
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB) Production Deployments In South East Asia, LATAM & India Are Now Available To Channel Partners & Financial Institutions
                                        </p>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'>New York /</span>
                                            February 25, 2022
                                        </p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-a-Marketplace-Technology-Co-Creating-Accelerated-Platforms-For-The-Adoption-fo?id=345353' target='_blank'>
                                                SmartCard Marketing Systems Inc (OTC:SMKG).
                                            </a>
                                        </h3>
                                        <p>
                                            SmartCard Marketing Systems Inc (SMKG:OTCQB), a Marketplace Technology Co. Creating Accelerated Platforms For The Adoption for Blockchains, Crypto Currencies, NFTs & Payments As a Service Offering
                                        </p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div>
                                        <img src={img1} alt="" className='width-100' />
                                    </div>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'> New York /</span> February 18, 2022</p>
                                        <h3>
                                            <a href='https://www.otcmarkets.com/stock/SMKG/news/SmartCard-Marketing-Systems-Inc-SMKGOTCQB-The-Tokenomics-for-Digital-Realm-Economies-Cross-border-and-Retail-with-the-th?id=344389' target='_blank' >
                                                SmartCard Marketing Systems Inc (OTC:SMKG).
                                            </a>
                                        </h3>
                                        <p>
                                        SmartCard Marketing Systems Inc (SMKG:OTCQB) The Tokenomics for Digital Realm Economies, Cross-border and Retail with the the CreareCoin Launch
                                        </p>
                                    </div>
                                </div>




                            </div>
                        </div>
                        {/* <div className='col-sm-4'>
                            <h3 className='text-center'>Recent News</h3>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <div className='bagg'>
                                        1
                                    </div>
                                </div>
                                <div className='col-sm-10'>
                                    <div className='pt-3'>
                                        <p><span className='primaryc'> TECHNOLOGY /</span> July 5th, 2023</p>
                                        <h6>
                                            SMARTCARD MARKETING SYSTEMS INC. (OTC:SMKG)
                                        </h6>
                                        <div className='color-light'>
                                            <FaFacebook />  2.5k {' '}
                                            <FaTwitter /> 1.6k
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default News