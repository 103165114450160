import React, { useState } from 'react'
import TopMenu from './TopMenu'
import NavbarMenu from './NavbarMenu'
import Footer from './Footer'

import qrimg from '../img/qr_code.png'
import productimg from '../img/product.jpeg'

import { Link } from 'react-router-dom'

function Products() {


    return (
        <div>
            <TopMenu />
            <NavbarMenu />
            <div className='bg-about'>
                <div className='container pt-5 about-a'>
                    <h2 className='text-light'>
                    OUR SOLUTIONS WITH PAAS LICENSING
                    </h2>
                    {/* <p className='text-light'>Home / Our Solutions</p> */}
                    <div>
                        <img src={qrimg} alt='' />
                    </div>
                </div>
            </div>
            <div>
                <div className='container pt-5 pb-5'>
                    <div className='row'>
                        <div className='col-sm-12 '>
                            <h2 className='h2-f'>Our Proprietary Solution Center / (PaaS / BaaS / Whitelable Licensing)</h2>
                            {/* Change to new update in 
                                BlueOUR PRODUCT PORTFOLIORemove thisCapitalise -OUR SOLUTIONS(PAAS LICENSING) */}
                            <p className='text1 pt-3'>
                                {/* At SMKG, we design and develop industry applications for E-Commerce, Cloud and mobility industry specific processes to enable the customer journey, optimizing operational efficiency, reducing costs, improving performance and capturing real-time data. Our expertise working with Device Manufacturers, Independent Software Vendors and Financial Service providers allows our customers to concentrate on their business strategy and marketing initiatives. Our team has extensive experience in working in projects in North America, China, Asia, India, Europe and Africa. The company’s base of operations is home in North America with satellite offices in India and the Philippines. */}
                                At SMKG, we design and develop cutting-edge industry applications tailored for
                                E-Commerce, Cloud, Blockchain, and Mobility-specific processes, enhancing the customer journey,
                                operational efficiency, and real-time data insights. Our comprehensive solutions include embedded
                                Blockchain Integration, Paytech and Payment platforms, Marketplace solutions and enabling Web 3.0 and
                                Banking 4.0 ecosystems.
                            </p>

                        </div>
                        <div className='col-sm-12'>
                            {/* <div className='bg-product'></div> */}
                            <img src={productimg} alt='' className='width-100' />
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </div>
    )
}

export default Products